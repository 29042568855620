<template>
  <div class="share-modal-wrapper modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered">Share with Participants</p>
      <button
        class="delete"
        aria-label="close"
        @click="$parent.close()"
      ></button>
    </header>
    <section class="modal-card-body pt-2 pb-6">
      <b-notification
        :active="showError"
        @close="$store.commit('clearError')"
        type="is-danger"
        >{{ showError ? error.message : "" }}</b-notification
      >
      <b-tabs
        position="is-centered"
        expanded
        v-model="selectTab"
        size="is-medium"
        class="share-modal-tabs is-multiline"
      >
        <b-tab-item
          label="Instructor Page"
          icon="account-box"
          value="instructor-page"
        >
        </b-tab-item>
        <b-tab-item label="List of Games" icon="gamepad-square" value="games">
        </b-tab-item>
        <b-tab-item label="List of Roles" icon="account-multiple" value="roles">
        </b-tab-item>
      </b-tabs>
      <div v-if="selectTab === 'instructor-page'" class="px-3">
        The easiest way to share games with external participants is to send
        them a link to your personal instructor page - which lists all your
        games.
        <br />
        <br />
        From there they can join randomly one of the games/positions available.
        <br /><br />

        <div class="box">
          <b-field grouped>
            <b-field
              label="Page status :"
              class="pr-6"
              custom-class="is-size-5"
            >
              <b-switch
                v-model="newActivePage"
                class="mt-2"
                @input="updateProfile"
              >
                <em>{{ newActivePage ? "Active" : "Disabled" }}</em>
              </b-switch>
            </b-field>
            <b-field label="Page title :" custom-class="is-size-5" expanded>
              <b-field v-if="!updatingPageName">
                <div class="control">
                  <div class="has-text-grey pt-2 is-inline-block">
                    {{
                      publicProfile.pageName.replace(
                        "My Instructor Page",
                        "All Games"
                      )
                    }}
                  </div>
                  <b-button
                    rounded
                    class="ml-5"
                    type="is-primary"
                    icon-left="pencil"
                    @click="updatingPageName = true"
                    >Edit
                  </b-button>
                </div>
              </b-field>
              <b-field grouped v-if="updatingPageName">
                <b-input
                  :value="
                    publicProfile.pageName.replace(
                      'My Instructor Page',
                      'All Games'
                    )
                  "
                  v-model="newPageName"
                  expanded
                  @keyup.enter.native="updateProfile()"
                ></b-input>
                <p class="control" v-if="updatingPageName">
                  <b-button
                    rounded
                    icon-left="check"
                    class="button is-success"
                    @click="updateProfile()"
                    >Update</b-button
                  >
                </p>
              </b-field>
            </b-field>
          </b-field>
        </div>
        <div v-if="!publicProfile.activePage" class="is-italic">
          <b class="has-text-danger"
            >Your instructor page is currently hidden.</b
          >
          Activate it to show the link.
        </div>
        <div v-if="publicProfile.activePage">
          Share this link by email or in your web conference chat :
          <div class="has-text-centered mt-3">
            <router-link
              target="_blank"
              rel="noreferrer"
              :to="`/user/${authUser.uid}`"
            >
              <b id="page-link" class="is-size-4 has-text-info">
                {{
                  zensimuSite && publicProfile.zsShortLink
                    ? publicProfile.zsShortLink
                    : publicProfile.shortLink
                }}
              </b>
            </router-link>
            <b-button
              rounded
              class="ml-4"
              type="is-warning"
              @click="copyBlock('page-link')"
              >Copy</b-button
            >
          </div>
        </div>
      </div>
      <div v-if="selectTab === 'games' || selectTab === 'roles'" class="px-3">
        <div v-if="selectedGames && selectedGames.length">
          <p>
            Share the below links to the participants by email or using the web
            conference chat.
          </p>
          <p>
            You have selected <b>{{ selectedGames.length }}</b> games,
            corresponding to <b>{{ rolesArray.length }}</b> player positions.
          </p>
          <div class="box mt-4">
            <div class="buttons is-right">
              <b-button rounded type="is-info" @click="downloadExcel()">
                Download as Excel file
              </b-button>
              <b-button
                rounded
                type="is-warning"
                @click="copyBlock('games-table')"
              >
                Copy table
              </b-button>
            </div>
            <div id="games-table">
              <b-table
                :data="selectTab === 'games' ? gamesArray : rolesArray"
                class="is-size-7"
              >
                <b-table-column
                  v-slot="props"
                  label="Game Name"
                  sortable
                  field="gameName"
                >
                  {{ props.row.gameName }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Game Link"
                  :visible="selectTab === 'games'"
                >
                  <router-link
                    :to="`/game/${props.row.gameId}`"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {{ props.row.gameLink }}
                  </router-link>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Role Name"
                  sortable
                  field="faciId"
                  :visible="selectTab === 'roles'"
                >
                  {{
                    props.row.faciName | capitalize({ onlyFirstLetter: true })
                  }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Role Link"
                  :visible="selectTab === 'roles'"
                >
                  <router-link
                    v-if="props.row.faciLink"
                    :to="`/game/${props.row.gameId}?join=${props.row.faciId}`"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {{ props.row.faciLink }}
                  </router-link>
                  <em v-if="!props.row.faciLink"
                    >Game finished -
                    <router-link
                      :to="`/game/${props.row.gameId}`"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {{ props.row.gameLink }}
                    </router-link></em
                  >
                </b-table-column>
              </b-table>
            </div>
            <!-- <ul id="games-link" class="mb-4">
              <li v-for="game in selectedGames" :key="game.id">
                {{ game.name }} :
                <router-link
                  :to="`/game/${game.id}`"
                  target="_blank"
                  rel="noreferrer"
                  >{{ getLink(game) }}</router-link
                >
              </li>
            </ul> -->
          </div>
        </div>
        <div
          class="is-size-5 has-text-grey-light has-text-centered"
          v-if="!selectedGames || !selectedGames.length"
        >
          No game selected.
          <br />
          <b-button
            rounded
            class="mt-4"
            type="is-info is-light"
            @click="$parent.close()"
            >Select from your games list</b-button
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import XlsxPopulate from "xlsx-populate";

export default {
  name: "ShareModal",
  computed: {
    ...mapGetters([
      "error",
      "showError",
      "publicProfile",
      "authUser",
      "portalLook",
      "zensimuSite"
    ]),
    gamesArray() {
      return this.selectedGames.map(game => {
        return {
          lid: game.id,
          gameName: game.name,
          gameId: game.id,
          gameLink: this.getLink(game)
        };
      });
    },
    rolesArray() {
      let tab = [];
      this.selectedGames.forEach(game => {
        for (let faciId in game.facilities) {
          let faci = game.facilities[faciId];
          tab.push({
            lid: `${game.id}-${faciId}`,
            gameName: game.name,
            gameId: game.id,
            gameLink: this.getLink(game),
            faciName: faci.name,
            faciId: faciId,
            faciLink: this.getFaciLink(game, faciId)
          });
        }
      });
      return tab;
    }
  },
  props: {
    selectedGames: Array
  },
  methods: {
    getLink(game) {
      if (this.portalLook) {
        return `https://${this.portalLook.slug}.zensimu.com/game/${game.id}`;
      } else if (this.zensimuSite) {
        return game.zsShortLink || `https://play.zensimu.com/game/${game.id}`;
      } else {
        return game.shortLink || `https://app.beergameapp.com/game/${game.id}`;
      }
    },
    getFaciLink(game, faciId) {
      if (game.finished) {
        return null;
      }
      if (this.portalLook) {
        return `https://${this.portalLook.slug}.zensimu.com/game/${game.id}?join=${faciId}`;
      } else if (this.zensimuSite) {
        return `https://play.zensimu.com/game/${game.id}?join=${faciId}`;
      } else {
        return `https://app.beergameapp.com/game/${game.id}?join=${faciId}`;
      }
    },
    updateProfile() {
      this.updatingPageName = false;
      if (
        this.newActivePage !== this.publicProfile.activePage ||
        this.newPageName !== this.publicProfile.pageName
      ) {
        this.$store.dispatch("updatePublicProfile", {
          uid: this.authUser.uid,
          obj: { activePage: this.newActivePage, pageName: this.newPageName }
        });
      }
    },
    downloadExcel() {
      let fileName =
        this.selectTab === "games" ? "games_list.xlsx" : "roles_list.xlsx";

      XlsxPopulate.fromBlankAsync().then(workbook => {
        // Modify the workbook.
        const sheet = workbook.sheet("Sheet1");
        sheet
          .row(1)
          .style("bold", true)
          .style("fill", "fbacad")
          .style("fontColor", "192e30")
          .style("wrapText", true);
        const rstart = sheet.cell("A1");
        if (this.selectTab === "roles") {
          rstart.value([["Game Name", "Role Name", "Role Link"]]);
          sheet.column("A").width(40);
          sheet.column("B").width(20);
          sheet.column("C").width(55);
          for (let ii = 0; ii < this.rolesArray.length; ii++) {
            sheet.cell(2 + ii, 1).value(this.rolesArray[ii].gameName);
            sheet.cell(2 + ii, 2).value(this.rolesArray[ii].faciName);
            sheet
              .cell(2 + ii, 3)
              .value(
                this.rolesArray[ii].faciLink || this.rolesArray[ii].gameLink
              )
              .style({ fontColor: "0563c1", underline: true })
              .hyperlink(
                this.rolesArray[ii].faciLink || this.rolesArray[ii].gameLink
              );
          }
          sheet.autoFilter(sheet.range("A1", "C1"));
        }
        if (this.selectTab === "games") {
          rstart.value([["Game Name", "Game Link"]]);
          sheet.column("A").width(40);
          sheet.column("B").width(40);
          for (let ii = 0; ii < this.gamesArray.length; ii++) {
            sheet.cell(2 + ii, 1).value(this.gamesArray[ii].gameName);
            sheet
              .cell(2 + ii, 2)
              .value(this.gamesArray[ii].gameLink)
              .style({ fontColor: "0563c1", underline: true })
              .hyperlink(this.gamesArray[ii].gameLink);
          }
          sheet.autoFilter(sheet.range("A1", "B1"));
        }
        // Write to file.
        return workbook.outputAsync().then(blob => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // If IE, you must uses a different method.
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }
        });
      });
    },
    copyBlock(bid) {
      let el = document.getElementById(bid);
      let body = document.body,
        range,
        sel;
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
          range.selectNodeContents(el);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(el);
          sel.addRange(range);
        }
      } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
      }
      document.execCommand("copy");
      this.$buefy.toast.open({
        message:
          bid === "games-table"
            ? "Copied ! You can paste-it into an Excel sheet"
            : "Copied !"
      });
    }
  },
  mounted() {
    if (this.selectedGames && this.selectedGames.length) {
      this.selectTab = "roles";
    }
    this.newActivePage =
      this.publicProfile && this.publicProfile.activePage ? true : false;
    this.newPageName =
      this.publicProfile && this.publicProfile.pageName
        ? this.publicProfile.pageName
        : "My Instructor Page";
    this.newPageName = this.newPageName.replace(
      "My Instructor Page",
      "All Games"
    );
  },
  data() {
    return {
      updatingPageName: false,
      selectTab: "instructor-page",
      newActivePage: false,
      newPageName: ""
    };
  }
};
</script>

<style lang="scss">
.share-modal-wrapper {
  #page-link {
    text-decoration: underline;
  }
  #games-link {
    max-height: 400px;
    overflow-y: scroll;
  }
  .b-tabs.share-modal-tabs .tab-content {
    display: none;
  }
}
</style>
