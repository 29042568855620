<template>
  <span class="timer-elapsed-wrapper">
    <b-icon icon="timer" class="pr-1" v-if="showClock" size="is-small"></b-icon>
    <span style="white-space: nowrap;">{{ currentCycleElapsed }}</span>
  </span>
</template>

<script>
export default {
  name: "TimerElapsed",
  props: {
    showClock: Boolean,
    startSec: Number,
    endSec: Number,
    isActive: Boolean
  },
  computed: {
    currentCycleElapsed() {
      let minutes = Math.max(0, Math.floor(this.elapsed / (60 * 1000)));
      let seconds = Math.max(
        0,
        Math.floor((this.elapsed - minutes * 60000) / 1000)
      );
      return (
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds)
      );
    }
  },
  created() {
    this.updateCycle();
  },
  methods: {
    updateCycle() {
      const nowMilli = this.endSec ? this.endSec * 1000 : Date.now();
      this.elapsed = this.startSec ? nowMilli - 1000 * this.startSec : 0;
      if (!this.isActive) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        return;
      }
      this.timeout = setTimeout(() => {
        this.updateCycle();
      }, 1000);
    }
  },
  watch: {
    isActive() {
      this.updateCycle();
    }
  },
  data() {
    return {
      elapsed: 0,
      timeout: null
    };
  }
};
</script>

<style lang="scss"></style>
