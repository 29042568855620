<template>
  <div class="games-tab">
    <section class="section pt-3">
      <div class="columns mb-0">
        <h1 class="column title is-hidden-mobile is-narrow pb-0">
          Games
        </h1>
        <div
          class="column buttons has-text-right-tablet has-text-centered-mobile mr-2 pb-0"
        >
          <router-link
            to="/dashboard/create"
            class="button is-medium is-info is-rounded mb-0"
            ><span class="is-hidden-mobile">Create New Game</span
            ><span class="is-hidden-tablet">New Game</span></router-link
          >
          <b-button
            size="is-medium"
            icon-left="share"
            type="is-warning"
            class="mb-0"
            rounded
            @click="showShareModal = true"
            >Invite<span class="is-hidden-mobile"> Participants</span></b-button
          >
        </div>
      </div>
      <div class="section pt-0">
        <b-collapse
          aria-id="myGamesBtns"
          animation="slide"
          :open="checkedRows.length ? true : false"
          class="pb-4"
        >
          <setup-buttons-list
            :selected-games="checkedRows"
            :is-creator-view="filterCreator === 'me'"
            @unselect="checkIds = []"
            :can-unselect="true"
          ></setup-buttons-list>
        </b-collapse>
        <div class="columns">
          <div class="column is-narrow">
            <b-tabs
              v-model="activeList"
              class="filter-gameslist-tabs"
              size="is-small"
            >
              <b-tab-item label="Inbox" value="inbox">
                <template slot="header">
                  Inbox
                  <b-tag rounded>
                    {{ countFilter.inbox }}
                  </b-tag>
                </template>
              </b-tab-item>
              <b-tab-item label="Archived" value="archived">
                <template slot="header">
                  Archived
                  <b-tag rounded>
                    {{ countFilter.archived }}
                  </b-tag>
                </template>
              </b-tab-item>
            </b-tabs>
          </div>
          <div class="column">
            <b-field grouped group-multiline>
              <b-select
                v-model="filterGameType"
                placeholder="Filter by game type"
                size="is-small"
              >
                <option :value="null">
                  All types
                </option>
                <option value="beergame">
                  Beer Game
                </option>
                <option value="leangame">
                  Lean Game
                </option>
              </b-select>
              <b-field>
                <b-input
                  size="is-small"
                  placeholder="Search game"
                  icon="magnify"
                  v-model="searchText"
                  expanded
                ></b-input>
              </b-field>
              <b-select
                size="is-small"
                v-if="
                  teamAccount && Object.keys(teamAccount.members).length > 1
                "
                v-model="filterCreator"
              >
                <option value="me">
                  Created by me
                </option>
                <option value="team">
                  Created by my team
                </option>
              </b-select>
              <b-field>
                <b-radio-button
                  v-model="filterType"
                  native-value="all"
                  size="is-small"
                >
                  All
                </b-radio-button>
                <b-radio-button
                  size="is-small"
                  v-model="filterType"
                  native-value="active"
                  type="is-success"
                >
                  Active
                </b-radio-button>
                <b-radio-button
                  size="is-small"
                  v-model="filterType"
                  native-value="finished"
                  type="is-danger"
                >
                  Finished
                </b-radio-button>
              </b-field>
            </b-field>
          </div>
        </div>
        <div
          class="notification"
          v-if="concatGamesList.length == 0 && !loading && mountTimer"
        >
          <span class="is-size-5">
            You haven't created any simulation yet. Create one now to discover
            how the app works !
          </span>
          <br /><br />
          <router-link
            to="/dashboard/create"
            class="button is-info is-medium is-rounded"
            >Create New Game</router-link
          >
        </div>
        <div class="box p-2">
          <b-table
            ref="gamestable"
            :data="tableGames ? tableGames : []"
            :mobile-cards="false"
            :checked-rows="checkedRows"
            @check="updateCheckRow"
            checkable
            scrollable
          >
            <b-table-column
              v-slot="props"
              label="Name"
              sortable
              field="name"
              width="320"
            >
              <b-tooltip
                style="cursor: help;"
                type="is-dark"
                :label="gameTypes[props.row.type].name"
              >
                <figure class="mr-1 image is-16x16 is-inline-block">
                  <img :src="gameTypes[props.row.type].logo" />
                </figure>
              </b-tooltip>
              <router-link
                :to="
                  `/game/${props.row.id}/${
                    props.row.finished ? 'results' : 'intro'
                  }`
                "
              >
                {{ props.row.name | capitalize({ onlyFirstLetter: true }) }}
              </router-link>
              <a
                v-if="props.row.myGame"
                class="has-text-primary"
                @click="updateName(props.row)"
              >
                <b-icon size="is-small" icon="pencil"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Date"
              sortable
              field="created_at"
            >
              <span class="has-text-primary">{{
                getCreationDate(props.row.created_at) | capitalize
              }}</span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Status"
              sortable
              field="finished"
            >
              <p
                :class="{
                  'has-text-dark': props.row.archived,
                  'has-text-success':
                    !props.row.archived && !props.row.finished,
                  'has-text-danger': !props.row.archived && props.row.finished
                }"
              >
                <b-icon
                  icon="checkbox-blank-circle"
                  size="is-small"
                  class="mr-1"
                ></b-icon>
                <span class="is-hidden-touch">{{
                  props.row.finished ? "Finished" : "Active"
                }}</span>
              </p>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Players"
              sortable
              field="players"
              width="1"
            >
              <span
                v-if="props.row.players"
                :class="{
                  'has-text-grey-light': props.row.players.length === 0
                }"
              >
                {{ props.row.players.length }}/{{
                  Object.keys(props.row.facilities).length
                }}
                <b-tooltip
                  style="cursor: help;"
                  type="is-dark"
                  :label="tooltips[props.row.id].players"
                  v-if="tooltips[props.row.id].players"
                  size="is-small"
                  multilined
                >
                  <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                </b-tooltip>
              </span>
            </b-table-column>
            <b-table-column v-slot="props" label="Orders" width="1">
              <span
                v-if="props.row.players && props.row.type === 'beergame'"
                :class="{
                  'has-text-grey-light': props.row.players.length === 0
                }"
              >
                {{
                  props.row.players.length -
                    tooltips[props.row.id].ordersNotVal
                }}/{{ props.row.players.length }}
                <b-tooltip
                  style="cursor: help;"
                  type="is-dark"
                  :label="tooltips[props.row.id].orders"
                  v-if="tooltips[props.row.id].orders"
                  size="is-small"
                  multilined
                >
                  <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                </b-tooltip>
              </span>
            </b-table-column>
            <b-table-column v-slot="props" label="Turn" sortable field="week">
              <p
                v-if="props.row.type === 'beergame'"
                :class="
                  props.row.finished ? 'has-text-danger' : 'has-text-success'
                "
              >
                <b-icon size="is-small" icon="flag" class="mr-1"></b-icon>
                {{ props.row.week }}<em>/{{ props.row.params.totalWeeks }}</em>
              </p>
            </b-table-column>
            <b-table-column v-slot="props" label="Result">
              <p
                v-if="tooltips[props.row.id].finalResult !== null"
                :class="{
                  'has-text-danger': tooltips[props.row.id].finalResult < 0,
                  'has-text-info': tooltips[props.row.id].finalResult >= 0
                }"
              >
                {{
                  tooltips[props.row.id].finalResult
                    | currency(props.row.params.currency)
                }}
              </p>
            </b-table-column>
            <b-table-column
              v-slot="props"
              custom-key="timer"
              label="Timer"
              :visible="showTimerColumn"
            >
              <timer-clock
                v-if="
                  !props.row.finished &&
                    !props.row.archived &&
                    props.row.type === 'beergame'
                "
                tooltip="Time left for current turn"
                :active="props.row.params.playTimer"
                :timerNextTick="props.row.params.timerNextTick"
                :in-game="false"
                :compact="true"
              ></timer-clock>
              <timer-elapsed
                v-if="
                  props.row.type === 'leangame' &&
                    props.row.params.game_start_at
                "
                :is-active="
                  props.row.params.game_start_at &&
                    !props.row.params.game_end_at
                "
                :start-sec="
                  props.row.params.game_start_at
                    ? props.row.params.game_start_at.seconds
                    : 0
                "
                :end-sec="
                  props.row.params.game_end_at
                    ? props.row.params.game_end_at.seconds
                    : 0
                "
              ></timer-elapsed>
            </b-table-column>
            <b-table-column
              v-slot="props"
              :visible="filterCreator !== 'me'"
              label="Created by"
              custom-key="creator-name"
            >
              {{ props.row.myGame ? "" : props.row.creatorName }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              custom-key="settings"
              :visible="filterCreator === 'me'"
            >
              <a
                v-if="props.row.myGame"
                class="has-text-grey-darker"
                @click="
                  isSettingsModalActive = true;
                  settingGameId = props.row.id;
                "
              >
                <b-icon icon="cog"></b-icon
              ></a>
            </b-table-column>
            <!-- <template slot="detail" slot-scope="props">
              <p
                v-if="
                  !props.row.finished &&
                    props.row.players &&
                    props.row.players.length
                "
                class="pt-2"
              >
                {{ props.row.players.length }} players connected :
                {{ tooltips[props.row.id].players }}. <br />{{
                  tooltips[props.row.id].orders
                }}
                to order.
                <br /><br />
              </p>
            </template> -->
          </b-table>
        </div>
      </div>
    </section>
    <b-modal :active.sync="isSettingsModalActive" has-modal-card>
      <bg-settings
        :game="settingsGame"
        v-if="settingsGame && settingsGame.type === 'beergame'"
      ></bg-settings>
      <lean-settings
        :game="settingsGame"
        v-if="settingsGame && settingsGame.type === 'leangame'"
      ></lean-settings>
    </b-modal>
    <b-modal :active.sync="showShareModal" has-modal-card>
      <share-modal :selected-games="checkedRows"></share-modal>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimerClock from "@/components/Dashboard/TimerClock.vue";
import SetupButtonsList from "@/components/Dashboard/SetupButtonsList.vue";
import ShareModal from "@/components/Dashboard/ShareModal.vue";
import BgSettings from "@/components/bg/Play/BgSettings.vue";
import LeanSettings from "@/components/lean/Play/LeanSettings.vue";
import TimerElapsed from "@/components/lean/Play/StationElements/TimerElapsed.vue";

export default {
  name: "gamestab",
  components: {
    SetupButtonsList,
    ShareModal,
    BgSettings,
    LeanSettings,
    TimerClock,
    TimerElapsed
  },
  mounted() {
    setTimeout(() => {
      this.mountTimer = true;
    }, 1000);
  },
  computed: {
    ...mapGetters([
      "concatGamesList",
      "teamAccount",
      "loading",
      "portalLook",
      "gameTypes"
    ]),
    settingsGame() {
      return this.concatGamesList.find(game => game.id === this.settingGameId);
    },
    checkedRows() {
      return this.concatGamesList.filter(game => {
        return this.checkIds.some(id => {
          return game.id === id;
        });
      });
    },
    showTimerColumn() {
      return this.tableGames.some(game => {
        return (
          game.type === "leangame" ||
          (!game.finished && !game.archived && game.params.playTimer)
        );
      });
    },
    countFilter() {
      return {
        inbox: this.filterUserGames.filter(game => {
          return !game.archived;
        }).length,
        active: this.filterUserGames.filter(game => {
          return !game.archived && !game.finished;
        }).length,
        finished: this.filterUserGames.filter(game => {
          return !game.archived && game.finished;
        }).length,
        archived: this.filterUserGames.filter(game => {
          return game.archived;
        }).length
      };
    },
    tooltips() {
      let obj = {};
      this.concatGamesList.forEach(game => {
        obj[game.id] = {};
        obj[game.id].players = this.playersNamesTooltip(game);
        obj[game.id].orders = this.waitingForTooltip(game);
        obj[game.id].ordersNotVal = this.ordersNotValidated(game).length;
        obj[game.id].finalResult = this.getFinalResult(game);
      });
      return obj;
    },
    filterUserGames() {
      return this.concatGamesList.filter(game => {
        let conditCreator =
          this.filterCreator === "all" ||
          (this.filterCreator === "me" && game.myGame) ||
          (this.filterCreator === "team" && !game.myGame);
        let conditGameType =
          !this.filterGameType || this.filterGameType === game.type;
        let conditStatus =
          this.filterType === "all" ||
          (this.filterType === "active" && !game.finished) ||
          (this.filterType === "finished" && game.finished);
        let conditSearch =
          game.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0;
        return conditCreator && conditStatus && conditSearch && conditGameType;
      });
    },
    tableGames() {
      return this.filterUserGames.filter(game => {
        let conditTab =
          (game.archived && this.activeList === "archived") ||
          (!game.archived && this.activeList !== "archived");
        return conditTab;
      });
    }
  },
  methods: {
    getCreationDate(created_at) {
      let crea = created_at ? new Date(created_at.seconds * 1000) : new Date();
      return crea.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "2-digit"
      });
    },
    updateCheckRow(list, row) {
      if (typeof row === "undefined") {
        if (this.checkIds.length !== this.tableGames.length) {
          this.checkIds = this.tableGames.map(gg => gg.id);
        } else {
          this.checkIds = [];
        }
      } else if (row && row.id) {
        let idRank = this.checkIds.indexOf(row.id);
        if (idRank > -1) {
          this.checkIds.splice(idRank, 1);
        } else {
          this.checkIds.push(row.id);
        }
      }
    },
    updateName(row) {
      this.$buefy.dialog.prompt({
        message: `Update the game name :`,
        cancelText: "Cancel",
        size: "is-medium",
        confirmText: "Update",
        inputAttrs: {
          type: "text",
          value: row.name,
          required: true,
          size: 40
        },
        type: "is-success",
        onConfirm: newName => {
          this.$store.dispatch("updateGameDoc", [
            { gid: row.id, obj: { name: newName } }
          ]);
        }
      });
    },
    playersNamesTooltip(game) {
      let stringNames = "";
      if (game.finished || game.archived) {
        return "";
      } else {
        Object.values(game.facilities).forEach(faci => {
          if (faci.user) {
            if (stringNames.length) {
              stringNames += ", ";
            }
            stringNames +=
              this.$options.filters.capitalize(faci.name) +
              (faci.playerName
                ? " (" + this.$options.filters.capitalize(faci.playerName) + ")"
                : "");
          }
        });
      }
      return stringNames;
    },
    getFinalResult(game) {
      if (!game.params) {
        return false;
      }
      let sumResult = 0;
      if (game.type === "beergame" && game.week > 0) {
        Object.keys(game.facilities).forEach(faciKey => {
          sumResult -= game.facilities[faciKey].cost;
          sumResult += game.facilities[faciKey].revenue;
        });
      }
      return sumResult || null;
    },
    ordersNotValidated(game) {
      return Object.values(game.facilities)
        .filter(faci => {
          return !faci.validatedOrder && faci.user;
        })
        .map(
          faci =>
            this.$options.filters.capitalize(faci.name) +
            (faci.playerName && faci.playerName.length
              ? ` (${this.$options.filters.capitalize(faci.playerName)})`
              : "")
        );
    },
    waitingForTooltip(game) {
      let onv = this.ordersNotValidated(game);
      if (
        onv.length == 0 ||
        game.players.length === 0 ||
        game.finished ||
        game.archived
      ) {
        return "";
      } else if (onv.length == 1) {
        return "Waiting for the " + onv[0];
      } else {
        return (
          "Waiting for the " +
          onv.slice(0, -1).join(", the ") +
          " and the " +
          onv.slice(-1)
        );
      }
    }
  },
  watch: {
    activeList() {
      this.checkIds = [];
    },
    filterCreator() {
      this.checkIds = [];
    },
    filterType() {
      this.checkIds = [];
    }
  },
  data() {
    return {
      filterGameType: null,
      isSettingsModalActive: false,
      showShareModal: false,
      checkIds: [],
      activeList: "inbox",
      settingGameId: null,
      searchText: "",
      mountTimer: false,
      filterType: "all",
      filterCreator: "me"
    };
  }
};
</script>

<style lang="scss">
.games-tab {
  .section.pt-0 {
    padding-top: 0 !important;
  }
  .month-title {
    margin-top: 0.2em;
    .icon {
      margin-right: 0.2em;
    }
  }
  .table-wrapper .image.is-inline-block {
    padding-top: 2px !important;
  }
  .nopadbot {
    padding-bottom: 0;
  }
  .has-background-primary {
    height: 1px;
    padding: 0;
    margin: 0 1.5em;
  }
  .b-tabs.filter-gameslist-tabs {
    padding: 0;
    .tab-content {
      display: none;
    }
  }
}
</style>
